<template>
  <div>
    <b-card>
      <vue-form-search
        ref="formSearch"
        @travel-loading="setLoading"
        @travel-result="getTravel"
      />
    </b-card>
    <vue-show-ticket
      v-if="travels.length > 0"
      :travels="travels"
      @companies-filter="companiesFilter"
    >
      <template v-slot:date-only>
        <b-row class="date-only">
          <b-col
            v-for="(item, index) in setDayFilter(5)"
            :key="index"
          >
            <b-card
              :class="`mb-0 hover-style-1 ${item.active}`"
              :bg-variant="item.bg"
              :text-variant="item.variant"
              @click="setDate(item.dateOrigin)"
            >
              <b-card-text class="text-center mb-0">
                {{ item.dayWord }}
              </b-card-text>
              <b-card-text class="text-center mb-0">
                {{ item.dayNumber }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </template>
    </vue-show-ticket>
    <div v-if="loadingSearch">
      <b-card>
        <div class="text-center">
          <h3>No hay viajes disponibles en este momento. Da clic en el siguiente <a href="https://walink.co/dac5a9" style="color: blue;">link</a> y comunícate con uno de nuestros asesores para obtener más ayuda.</h3>
        </div>
      </b-card>
    </div>
    <vue-content-loading v-if="loading" />
  </div>
</template>

<script>

import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import FormSearch from '@/components/SearchTicket/Ticket/FormTicket.vue'
import ShowTicket from '@/components/SearchTicket/Ticket/ShowTicket.vue'
import ContenLoadingTicket from '@/components/SearchTicket/Loading/ContentLoading.vue'
import moment from 'moment'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    'vue-form-search': FormSearch,
    'vue-show-ticket': ShowTicket,
    'vue-content-loading': ContenLoadingTicket,
  },
  data() {
    return {
      travels: [],
      travelsSave: [],
      loading: false,
      loadingSearch: false,
    }
  },
  mounted() {
    if ('token' in this.$route.query) {
      store.dispatch('userApp/setTokenPassword', this.$route.query.token)
    } else {
      store.dispatch('userApp/setTokenPassword', null)
    }
    moment.locale('es')
    this.setDayFilter(5)
  },
  methods: {
    getTravel(data) {
      if (data.length === 0) {
        this.loadingSearch = true
      } else {
        this.loadingSearch = false
      }
      this.travels = data
      this.travelsSave = data
    },
    setLoading(value) {
      if (value) {
        this.travels = {}
      }
      this.loading = value
    },
    setDayFilter(value) {
      const arrayDate = Array.from({ length: value }, (_, i) => {
        const fecha = moment(this.$route.query.dateOrigin).add(i, 'day')
        const fechaLetras = fecha.format('dddd')
        const fechaNumber = fecha.format('DD')
        const bg = i === 0 ? 'primary' : ''
        const variant = i === 0 ? 'white' : ''
        const active = i === 0 ? 'active-card' : ''
        return {
          dayWord: fechaLetras.charAt(0).toUpperCase() + fechaLetras.slice(1),
          dayNumber: fechaNumber,
          dateOrigin: fecha.format('YYYY-MM-DD'),
          bg,
          variant,
          active,
        }
      })
      return arrayDate
    },
    setDate(value) {
      this.$refs.formSearch.date_of_salida = value
      this.$refs.formSearch.send()
    },
    companiesFilter(value) {
      if (value.length > 0) {
        this.travels = this.travelsSave.filter(element => value.includes(element.empresa))
      } else {
        this.travels = this.travelsSave
      }
    },
  },
}
</script>

<style>

</style>
