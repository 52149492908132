<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          cols="12"
          sm="3"
        >
          <b-form-group
            label-for="Origen"
            label="Origen"
          >
            <vue-autosuggest
              ref="autocompleteCityOrigin"
              v-model="CityOriginquery"
              :suggestions="suggestions"
              :input-props="inputProps"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="getSuggestionValue"
              @input="fetchResults"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="3"
        >
          <b-form-group
            label-for="Destino"
            label="Destino"
          >
            <vue-autosuggest
              ref="autocompleteCityDestino"
              v-model="CityDestinoquery"
              :suggestions="suggestionsDestino"
              :input-props="inputPropsDestino"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestionDestino"
              :get-suggestion-value="getSuggestionValueDestino"
              disabled
              @input="fetchResultsDestino"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="2"
        >
          <b-form-group
            label="Fecha de salida"
            label-for="Fecha de salida"
          >
            <VueDatePicker
              v-model="date_of_salida"
              fullscreen-mobile
              placeholder="Fecha de salida"
              color="#152848"
              :allowed-dates="allowedDates"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="2"
        >
          <b-form-group
            label="Fecha de regreso"
            label-for="Fecha de regreso"
          >
            <VueDatePicker
              v-model="date_of_regreso"
              fullscreen-mobile
              placeholder="Fecha de regreso"
              color="#152848"
              :allowed-dates="allowedDatesComeBack"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="2"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mt-0 mt-md-2"
            block
            :disabled="loading || city_origen === null || city_destino === null || date_of_salida === null"
            @click="send"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-25"
            />
            <span class="align-middle">BUSCAR</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>

import {
  BCol, BRow, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import { formatJsonForm } from '@/libs/utils'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'FormSearch',
  components: {
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BButton,
    VueAutosuggest,
    VueDatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      date_of_salida: null,
      date_of_regreso: null,
      city_origen: null,
      city_destino: null,
      loading: false,
      rowCityOrigen: [],
      rowCityDestino: [],

      // autosugges
      CityOriginquery: '',
      CityDestinoquery: '',
      results: [],
      timeout: null,
      selected: null,
      selectedDestino: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: 'autosuggest__input_ajax_city_origin',
        placeholder: 'Ciudad de origin',
        class: 'form-control',
        name: 'ajax',
      },
      inputPropsDestino: {
        id: 'autosuggest__input_ajax_city_destino',
        placeholder: 'Ciudad de destino',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      suggestionsDestino: [],
      sectionConfigs: {
        cityOrigin: {
          limit: 6,
          label: 'Ciudad de origen',
          onSelected: selected => {
            this.city_origen = selected.item
          },
        },
        cityDestino: {
          limit: 6,
          label: 'Ciudad de destino',
          onSelected: selectedDestino => {
            this.city_destino = selectedDestino.item
          },
        },
      },
    }
  },
  async mounted() {
    this.date_of_salida = this.$route.query.dateOrigin
    this.date_of_regreso = this.$route.query.dateDestination == 'null' ? null : this.$route.query.dateDestination
    if (this.$route.query.dateOrigin && this.$route.query.cityOrigin && this.$route.query.cityDestination) {
      this.city_origen = await this.setCity(this.$route.query.cityOrigin)
      this.city_destino = await this.setCity(this.$route.query.cityDestination)
      this.CityOriginquery = this.capitalize(this.city_origen.name)
      this.suggestions.push({ name: 'cityOrigin', data: [this.city_origen] })
      this.CityDestinoquery = this.capitalize(this.city_destino.name)
      this.suggestionsDestino.push({ name: 'cityDestino', data: [this.city_destino] })
      this.send()
    }
  },
  methods: {
    capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    },
    allowedDates(date) {
      const today = new Date().toISOString().substring(0, 10)
      return today <= date
    },
    allowedDatesComeBack(date) {
      const today = this.date_of_salida
      return today < date
    },
    async fetchResults() {
      const { CityOriginquery } = this
      this.selected = null
      this.suggestions = []
      if (CityOriginquery.length >= 2) {
        const cityOrigin = await this.$http.get('/v1/city', {
          params: {
            search: CityOriginquery.toLowerCase(),
          },
        }).then(res => res)
        this.suggestions = []
        this.suggestions.push({ name: 'cityOrigin', data: cityOrigin.data.data })
      }
    },
    renderSuggestion(suggestion) {
      return this.capitalize(suggestion.item.name)
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return this.capitalize(item.name)
    },
    async fetchResultsDestino() {
      const { CityDestinoquery } = this
      this.selectedDestino = null
      this.suggestionsDestino = []
      if (CityDestinoquery.length >= 2) {
        const cityDestino = await this.$http.get('/v1/city', {
          params: {
            search: CityDestinoquery.toLowerCase(),
            not_city: this.city_origen.id,
          },
        }).then(res => res)
        this.suggestionsDestino = []
        this.suggestionsDestino.push({ name: 'cityDestino', data: cityDestino.data.data })
      }
    },
    renderSuggestionDestino(suggestion) {
      return this.capitalize(suggestion.item.name)
    },
    getSuggestionValueDestino(suggestion) {
      const { item } = suggestion
      return this.capitalize(item.name)
    },
    async setCity(withCity) {
      return this.$http.get('/v1/city', {
        params: {
          with_city: withCity,
        },
      }).then(res => res.data.data[0])
    },
    send() {
      this.searchTravel(
        this.city_origen.id,
        this.city_destino.id,
        this.date_of_salida,
        this.date_of_regreso,
      )
    },
    searchTravel(
      cityOrigen,
      cityDestino,
      dateOrigin,
      dateDestino,
    ) {
      this.$router.replace({
        name: 'home',
        query: {
          cityOrigin: cityOrigen,
          cityDestination: cityDestino,
          dateOrigin,
          dateDestination: dateDestino,
        },
      })
      this.$emit('travel-loading', true)
      this.loading = true
      this.$http.get('/v1/soap/travel', {
        params: {
          cityOrigen,
          cityDestino,
          dateOrigin,
          dateDestino,
        },
      }).then(res => {
        this.loading = false
        this.$emit('travel-result', formatJsonForm(res.data))
        this.$emit('travel-loading', false)
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'A ocurriddo un error',
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>
<style scope>
.vd-picker__input {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.1rem 0.7rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
