<template>
  <div>
    <b-sidebar v-model="show" bg-variant="white" right backdrop shadow width="800px" @hidden="setShow" @shown="shown">
      <div v-if="loadingContent" class="px-3 py-2">
        <vue-loading />
      </div>
      <template #header="{ hide }" v-if="!loadingContent && Object.keys(travelData).length > 0">
        <div class="bg-primary">
          <b-button variant="flat-secondary" class="btn-icon rounded-circle" size="sm" @click="hide">
            <feather-icon class="text-white" icon="XIcon" />
          </b-button>
        </div>
        <div class="bg-primary text-light text-light px-2 py-1">
          <div>
            <strong class="mr-auto">{{ travelData.LocalidadOrigenNombre }} </strong> -
            <strong class="mr-auto mb-0">{{ travelData.LocalidadDestinoNombre }}</strong>
          </div>
          <div>
            <strong class="mr-auto">Salida: {{ travelData.FechaPartida.split(' ')[0] }} - {{ travelData.FechaPartida.split(' ')[1] }}</strong> -
            <strong class="mr-auto">Llegada: {{ travelData.FechaArribo.split(' ')[0] }} - {{ travelData.FechaArribo.split(' ')[1] }}</strong>
          </div>
        </div>
      </template>
      <div class="px-3 py-2" v-if="!loadingContent && Object.keys(travelData).length > 0">
        <b-overlay :show="showOverlay" rounded="sm" @hidden="onHidden">
          <template #overlay>
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="24"
              />
              <p id="cancel-label">
                Estamos creando la transacción...
              </p>
            </div>
          </template>
          <b-card>
            <vue-butacas-select
              v-if="butacas"
              :rows="butacas.Filas"
              :cols="butacas.Columnas"
              :cell="butacas.cell"
              :tarifa="travelDataProps.tarifa"
              :travelData="travelDataProps"
              :tipobus="setTypeBus(travelDataProps.tarifa)"
              @total-seat="setTotalButacas"
            />
          </b-card>
        </b-overlay>
      </div>
      <template #footer="{ hide }" v-if="!loadingContent">
        <hr>
        <div class="text-primary text-light px-2 py-1">
          <h3 v-if="totalButacas == 0" class="text-primary">No has seleccionado las sillas</h3>
          <b-row v-else>
            <b-col>
              <h3 class="text-primary">TOTAL</h3>
            </b-col>
            <b-col>
              <h2 class="text-primary">$ {{ formatPrice(pricingTotal) }}</h2>
            </b-col>
            <b-col>
              <b-button variant="primary" block size="sm" :disabled="showOverlay" @click="goToPayments">
                Continuar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, BCard, BRow, BCol, BOverlay, BIcon } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ContenLoadingButacas from '@/components/SearchTicket/Loading/SeatsLoading.vue'
import ButacasSelect from '@/components/SearchTicket/Seats/SeatsSelect.vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { formatJson } from '@/libs/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BOverlay,
    BIcon,
    AppTimeline,
    AppTimelineItem,
    "vue-loading": ContenLoadingButacas,
    "vue-butacas-select": ButacasSelect
  },
  directives: {
    Ripple,
  },
  props: {
    sidebarShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    travelData: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {
      loadingContent: true,
      butacas: null,
      totalButacas: 0,
      pricingTotal: 0,
      selectSeat: null,
      showOverlay: false,
      travelDataProps: null
    }
  },
  computed: {
    show: {
      get: function () {
        return this.sidebarShow
      },
      // setter
      set: function (newValue) {
        return newValue
      }
    },
    ...mapGetters({ userData: 'userApp/getProfileData' }),
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
      {
        currency: 'COP',
      }).format(price)
    },
    onHidden() {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus()
    },
    setTotalButacas(value) {
      this.totalButacas = value.total
      this.pricingTotal = value.pricingTotal
      this.selectSeat = value.selected
    },
    setShow() {
      this.butacas = null
      this.totalButacas = 0
      this.pricingTotal = 0
      this.$emit('close')
    },
    formatSeatMap(seatMap) {
      const { Categoria, CategoriaNombre, Coche, Columnas, Filas, Id, Name, Piso } = seatMap['@attributes'];
      const cell = seatMap.cell.map((row) => row['@attributes']);
      return {
        BloqueoCodigo: 0,
        Categoria: Categoria,
        CategoriaNombre: CategoriaNombre,
        Coche: Coche,
        Columnas: parseInt(Columnas),
        Filas: parseInt(Filas),
        Id: parseInt(Id),
        Name: Name,
        Piso: parseInt(Piso),
        cell: cell
      };
    },
    getMapaButacas() {
      return this.$http.post(`/v1/soap/mapa/butacas`, {
        ViajeID: this.travelData.idViaje,
        TerminalOrigenID: this.travelData.TerminalOrigenID,
        TerminalDestinoID: this.travelData.TerminalDestinoID,
        Orientacion: 1,
        Modo: 1,
        InfoAdicional: 0,
        company_id: this.travelData.company_id,
      }).then(res => {
        this.loadingContent = false
        return {
          data: res.data.sector,
          status: 200
        }
      }).catch(error => {
        return {
          data: error,
          status: 400
        }
      })
    },
    getTravel() {
      return this.$http.post(`/v1/soap/travel/search/by/viaje-id`, {
        company_id: this.travelData.company_id,
        city_origen: this.$route.query.cityOrigin,
        city_destino: this.$route.query.cityDestination,
        date_origin: this.$route.query.dateOrigin,
        date_destino: this.$route.query.dateDestination
      }).then(res => {
        this.loadingContent = false
        return {
          data: formatJson(res.data),
          status: 200
        }
      }).catch(error => {
        return {
          data: error,
          status: 400
        }
      })
    },
    // [x] Convertir la función formatJson en algo reutilizable
    // [x] Filtrar el viaje con el que ya se envia como props
    // [x] Filtrar el precio por la categoriaID de las sillas
    // [x] Sustituir el viaje buscado con el nuevo viaje que se busca de la db para que las tarifas si cambian sean las correctas
    // [x] Pintar el precio de acuerdo a la tarifa que esta de primero en el array y el tipo de bus
    // [x] Filtrar el precio de la silla con el categoriaId de la tarifa del viaje
    // [x] Crear la transacción
    // [x] Al pagar y que el pago este en estado aprobado generar aprobar la trasancción, para ello se debe aplicar una opción de pago
    // [x] Añadir pwa 
    // [x] Realizar el admin para mostrar todas las transacciones con su historial de pago
    // [x] Realizar el notificacion para recuperación de password
    // [x] Realizar el notificacion para bienvenida al usuario
    // [ ] Realizar el notificacion para las ventas al administrador
    // [x] Realizar el notificacion para el usuario comprador sobre el estado de su pago
    // [x] Realizar el notificacion para el usuario comprador sobre el estado de su tiquete cuando ya este aprobado
    // [ ] Generar listado de usuarios para administrar
    // [ ] Generar listado de transacciones que hace un usuario
    // [ ] Generar listado de viajes de usuarios
    // [ ] Generar función para reportes de ventas 
    // [ ] Generar dashboard para los usuarios
    // [ ] PasajeNumero consultar el getinfo del tiquete luego de creado para añadir la númeracion de la empresa
    // [ ] Realizar manual de la aplicación
    // [x] Mejorar componente de buscado de ciudades
    // [x]Reparar bug relacionado a activacion o desactivacion de documento y tipos de pagos en el sistema
    // [x]Bug que quitaba el tipo de bus
    // [x]Bug que mostraba que no existia el ticket
    async shown() {
      this.travelDataProps = await this.getTravel()
      this.travelDataProps = this.travelDataProps.data.filter(element => element.idViaje == this.travelData.idViaje)[0]
      this.loadingContent = true
      let resultButacas= await this.getMapaButacas()
      if(resultButacas.status == 200) {
        this.loadingContent = false
        this.butacas = this.formatSeatMap(resultButacas.data)
      } else {
        this.loadingContent = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: "Ha ocurrido un error al mmomento de cargar las sillas",
          },
        },
        {
          position: 'top-center',
        })
        this.setShow()
      }
    },
    setPricingCategoria(value) {
      const pricingSilla = Array.isArray(this.travelDataProps.tarifa) ? this.travelDataProps.tarifa : [this.travelDataProps.tarifa];
      let pricing = pricingSilla.filter(element => element.Categoria == value.CategoriaID).map((row) => row['@attributes'])[0]
      return pricing.PrecioOneWay
    },
    setTarifaId(value) {
      const pricingSilla = Array.isArray(this.travelDataProps.tarifa) ? this.travelDataProps.tarifa : [this.travelDataProps.tarifa];
      let pricing = pricingSilla.filter(element => element.Categoria == value.CategoriaID).map((row) => row['@attributes'])[0]
      return pricing.TarifaID
    },
    setPricing(value) {
      const travels = Array.isArray(value) ? value : [value];
      return travels[0]['@attributes']['PrecioOneWay']
    },
    setTypeBus(value) {
      const travels = Array.isArray(value) ? value : [value];
      return travels[0]['@attributes']['CategoriaNombre']
    },
    setDataTravel() {
      let chairs_select = this.selectSeat.map((element) => {
        return {
          label: element.Label,
          first_name: "",
          last_name: "",
          gender: "",
          type_document: "",
          document: "",
          date_of_birth: "",
          telefono: "",
          email: "",
          importe_base: parseFloat(this.setPricingCategoria(element)), // [x] Problema relacionado a la tarifa 
          tarifa_id: this.setTarifaId(element), // [x] Problema relacionado a la tarifa 
          NacionalidadPaisID: 170
        }
      })
      return {
        company_id: this.travelDataProps.company_id,
        user_id: this.userData.id,
        document: this.userData.document,
        email: this.userData.email,
        telefono: this.userData.phone_number,
        id_source: this.travelDataProps.TerminalOrigenID,
        id_destination: this.travelDataProps.TerminalDestinoID,
        source: this.travelDataProps.LocalidadOrigenNombre,
        destination: this.travelDataProps.LocalidadDestinoNombre,
        departure_date: this.travelDataProps.FechaPartida,
        arrival_date: this.travelDataProps.FechaArribo,
        chairs_select: chairs_select,
        viaje_id: this.travelDataProps.idViaje,
        unit_price: null,
        total_price: parseFloat(this.pricingTotal),
        TransaccionID: "",
        type_payment_id: "",
        status_id: 3,
        type_bus: this.setTypeBus(this.travelDataProps.tarifa)
      }
    },
    setTypeBus(value) {
      const travels = Array.isArray(value) ? value : [value];
      return travels[0]['@attributes']['CategoriaNombre']
    },
    goToPayments() {
      this.showOverlay = true
      this.$http.post('/v1/soap/create/transaction',
        this.setDataTravel()
      ).then(res => {
        this.showOverlay = false
        this.$router.push({ name: "Payments", params: { payment_id: res.data.id } })
      })
    },

  }
}
</script>
<style>
.b-sidebar>.b-sidebar-header {
  display: block;
  padding: 0px;
}

.f-1 {
  font-size: 15px;
}
</style>