<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="text-primary">
          Filtrar por:
        </h3>
        <b-card>
          <vue-ticket-filter
            :travels="travels"
            @companies-filter="companiesFilter"
          />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <slot name="date-only" />
        <vue-ticket
          :travels="travels"
          @travel-butacas="setButacas"
        />
        <vue-butacas
          :sidebar-show="sidebarShow"
          :travel-data="travelData"
          @close="close"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Ticket from '@/components/SearchTicket/Ticket/Ticket.vue'
import Filter from '@/components/SearchTicket/Ticket/Filter.vue'
import Butacas from '@/components/SearchTicket/Seats/Seats.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    'vue-ticket': Ticket,
    'vue-ticket-filter': Filter,
    'vue-butacas': Butacas,
  },
  props: {
    travels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sidebarShow: false,
      travelData: {},
    }
  },
  methods: {
    companiesFilter(value) {
      this.$emit('companies-filter', value)
    },
    setButacas(travel) {
      this.sidebarShow = !this.sidebarShow
      this.travelData = travel
    },
    close() {
      this.travelData = {}
      this.sidebarShow = false
    },
  },
}

</script>
