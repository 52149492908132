<template>
  <div>
    <template v-for="(travel, key) in travels">
      <vue-card-ticket
        :key="key"
        :travel="travel"
        @travel-butacas="setButacas"
      >
        <template v-slot:img-logo>
          <b-img
            width="120"
            :src="setImg(travel)"
          />
        </template>
      </vue-card-ticket>
    </template>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import CardTicket from '@/components/SearchTicket/Ticket/CardTicket.vue'

export default {
  components: {
    BImg,
    'vue-card-ticket': CardTicket,
  },
  props: {
    travels: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setImg(travels) {
      return `${process.env.VUE_APP_URL_STORAGE}${travels.photo}`
    },
    setButacas(travel) {
      this.$emit('travel-butacas', travel)
    },
  },
}

</script>
