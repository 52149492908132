<template>
  <div>
    <div class="demo-inline-spacing">
      <b-button
        v-for="(policye, key) in policies"
        :key="key"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        pill
        @click="setPolicy(policye.description)"
      >
        {{ policye.title }}
      </b-button>
      <br>
    </div>
    <div>
      <b-collapse
        v-model="visible"
        class="mt-2"
      >
        <b-card
          no-body
          class="border mb-0 p-1"
        >
          <b-card-text class="card-text">
            {{ policy }}
          </b-card-text>
        </b-card>
      </b-collapse>
    </div>
  </div>

</template>

<script>

import {
  BCollapse, BButton, VBToggle, BCard, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    policies: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      policy: '',
    }
  },
  methods: {
    setPolicy(policy) {
      this.policy = policy
      this.visible = !this.visible
    },
  },
}
</script>
